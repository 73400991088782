import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import LogoType from '../images/LogoType.png'

import './Header.css'

const Header = ({ siteTitle }) => {
  return (
    <header className="header">
      <div>
        <h1>
          <Link
            to="/"
            style={{
              textDecoration: `none`,
            }}
          >
            <img src={LogoType} alt="Charlie Imhoff" style={{ width: 300 }} />
          </Link>
        </h1>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
